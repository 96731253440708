@import '~antd/dist/antd.css';
.form-wrapper {
	text-align: center;
	width: 80%;
	margin: 0 auto;
}

.loginWrapper button {
	margin: 0 auto;
	display: block;
}

.header {
	background-color: #40a9ff;
	display: flex;
	flex-direction: column-reverse;
}

ul {
	list-style-type: none;
	margin: 0 auto;
}

.disp {
	/* color: aquamarine; */
	border: aquamarine 1px solid;
	border-radius: 5px;
	padding: 1em;
	margin-bottom: 2em;
	width: 90%;
}

.fail {
	color: red;
}

.logout button {
	display: inline;
	margin-top: 20px;
	margin-left: auto;
	align-items: flex-end;
}

.homediv {
	width: 40%;
	margin: 0 auto;
	padding: 10px;
	text-align: center;
}

.userBtn {
	margin: 10px;
}

.admnBtn {
	/* background-color: palevioletred; */
}

.submitted {
	color: red;
}
